// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/context/FormModalContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/context/FormModalContext.tsx");
  import.meta.hot.lastModified = "1736894247000";
}
// REMIX HMR END

import React, { createContext, useCallback, useContext, useReducer } from 'react';
import dayjs from 'dayjs';
const FormModalContext = createContext(undefined);
export const useFormModalContext = () => {
  _s();
  const context = useContext(FormModalContext);
  if (!context) {
    throw new Error('useFormModalContext must be used within a FormModalProvider');
  }
  return context;
};
_s(useFormModalContext, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
const formModalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TASK':
      return {
        ...state,
        defTask: {
          ...state.defTask,
          ...action.payload?.defTask
        },
        selectedTask: action.payload?.task,
        open: true,
        activeTab: 'task',
        itsCalendar: action.itsCalendar
      };
    case 'SET_LEAD':
      return {
        ...state,
        defLead: {
          ...state.defLead,
          ...action.payload?.defLead
        },
        selectedLead: action.payload?.lead,
        campValues: action.payload?.campValues || {},
        open: true,
        activeTab: 'lead'
      };
    case 'SET_TODO':
      return {
        ...state,
        selectedTodo: action.payload,
        open: true,
        activeTab: 'todo'
      };
    case 'SET_SUBUSER':
      return {
        ...state,
        selectedSubUser: action.payload,
        open: true,
        activeTab: 'subUser'
      };
    case 'SET_CLOSURE':
      return {
        ...state,
        defClosure: {
          ...state.defClosure,
          ...action.payload?.defClosure
        },
        selectedClosure: action.payload?.closure,
        actClientInfo: action.payload?.actClientInfo,
        cliid: action.payload?.cliid,
        open: true,
        activeTab: 'closure'
      };
    case 'SET_ACTIVE_TAB':
      if (action.payload === 'task') {
        return {
          ...state,
          activeTab: action.payload,
          open: true,
          defTask: {
            ...state.defTask,
            TARVENCIMIENTO: dayjs().format("YYYY-MM-DD"),
            TARHINI: dayjs().format("HH:mm:ss"),
            TARHFIN: dayjs().add(0.25, 'hour').format('HH:mm:ss')
          }
        };
      }
      return {
        ...state,
        activeTab: action.payload
      };
    case 'SET_OPEN':
      return {
        ...state,
        open: action.payload
      };
    case 'SET_CREATING_TASK':
      return {
        ...state,
        creatingTask: action.payload
      };
    case 'CLEAR':
      return {
        ...state,
        selectedTask: undefined,
        selectedLead: undefined,
        selectedTodo: undefined,
        selectedSubUser: undefined,
        selectedClosure: undefined,
        campValues: {},
        defTask: deftaskData,
        defLead: defLeadData,
        open: false,
        activeTab: 'task',
        itsCalendar: undefined
      };
    default:
      return state;
  }
};
const deftaskData = {
  TTAID: 1,
  CLIID: [],
  TARCONTENIDO: "",
  TARPRIVADA: 0,
  TARTIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  TARVENCIMIENTO: "",
  REPID: null,
  TARHINI: "",
  TARHFIN: "",
  TARDIRECCION: "",
  TARLINK: "",
  TAREMAIL: "",
  TARMENSAJE: "",
  NOTAS: [],
  RECORDATORIOS: [],
  SUBT: [],
  subuser: null,
  create_task_supervisor: false
};
const defLeadData = {
  CESID: 5,
  CLINOMBRE: "",
  CLIAPELLIDO: "",
  CLICONTACTO: "",
  CLIEMAIL1: "",
  CLIFNACIMIENTO: dayjs().format("YYYY-MM-DD"),
  CLINOTAS: "",
  CLICLAVES: null
};
const defClosureData = {
  PERCONID: 1,
  CIENOTA: "",
  CIETITULO: "",
  CIEARC: []
};
export const FormModalProvider = ({
  children
}) => {
  _s2();
  const initialState = {
    open: false,
    defTask: deftaskData,
    selectedTask: undefined,
    defLead: defLeadData,
    selectedLead: undefined,
    campValues: {},
    selectedTodo: undefined,
    selectedSubUser: undefined,
    defClosure: defClosureData,
    selectedClosure: undefined,
    activeTab: "task",
    creatingTask: false,
    cliid: undefined
  };
  const [state, dispatch] = useReducer(formModalReducer, initialState);
  const {
    activeTab,
    open,
    defTask,
    selectedTask,
    defLead,
    selectedLead,
    campValues,
    selectedTodo,
    selectedSubUser,
    defClosure,
    selectedClosure,
    actClientInfo,
    creatingTask,
    cliid
  } = state;
  const handleFormModal = useCallback(({
    tab,
    data,
    itsCalendar
  }) => {
    switch (tab) {
      case "task":
        const {
          task,
          defTask
        } = data || {};
        const fallbackTimes = {
          TARHINI: defTask?.TARHINI || dayjs().format('HH:mm:ss'),
          TARHFIN: defTask?.TARHFIN || dayjs().add(0.25, 'hour').format('HH:mm:ss'),
          TARVENCIMIENTO: defTask?.TARVENCIMIENTO || dayjs().format('YYYY-MM-DD')
        };
        dispatch({
          type: 'SET_TASK',
          payload: {
            task,
            defTask: {
              ...defTask,
              ...fallbackTimes
            }
          },
          itsCalendar
        });
        break;
      case "lead":
        dispatch({
          type: 'SET_LEAD',
          payload: data
        });
        break;
      case "todo":
        dispatch({
          type: 'SET_TODO',
          payload: data
        });
        break;
      case "subUser":
        dispatch({
          type: 'SET_SUBUSER',
          payload: data
        });
        break;
      case "closure":
        dispatch({
          type: 'SET_CLOSURE',
          payload: data
        });
      default:
        break;
    }
  }, []);
  const setTab = useCallback(tab => {
    dispatch({
      type: 'SET_ACTIVE_TAB',
      payload: tab
    });
  }, []);
  const setOpen = useCallback(open => {
    dispatch({
      type: 'SET_OPEN',
      payload: open
    });
  }, []);
  const setCreatingTask = useCallback(creating => {
    dispatch({
      type: 'SET_CREATING_TASK',
      payload: creating
    });
  }, []);
  const handleClose = useCallback(() => {
    dispatch({
      type: 'CLEAR'
    });
  }, []);
  return <FormModalContext.Provider value={{
    open,
    defTask,
    selectedTask,
    defLead,
    selectedLead,
    campValues,
    selectedTodo,
    selectedSubUser,
    defClosure,
    selectedClosure,
    actClientInfo,
    cliid,
    activeTab,
    creatingTask,
    setCreatingTask,
    handleFormModal,
    setOpen,
    setTab,
    handleClose,
    itsCalendar: state.itsCalendar
  }}>
            {children}
        </FormModalContext.Provider>;
};
_s2(FormModalProvider, "kundlmy7RMOZaXuhPWF0birAzWk=");
_c = FormModalProvider;
var _c;
$RefreshReg$(_c, "FormModalProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;